import style from './style.css';
import { Link } from 'preact-router/match';
import { h } from 'preact';

const NavigationList = (props) => {

  const mainStyle = props.globalNavStyle === 'desktop' ? `desktop` : props.burgerState === 'open' ? `burgerOpen` : `burgerClose`;
  const themeStyle = props.theme === 'dark' ? `dark` : `light`;

  return (
      <nav className={`${style[mainStyle]} ${style[themeStyle]}`} >
      <ul>
        {props.data &&
          props.data.nav &&
          props.data.nav.map((item, index) => {
            return (
              <li key={`content-nav-${index}`}>
                <Link activeClassName={style.active}
                      href={`${item.id}/${props.language}`}
                      onClick={(e) => props.updateBurgerState('close')}
                >
                  {item.name}
                </Link>
              </li>
            );
          })}
      </ul>
    </nav>);
}

export default NavigationList;
