import { h } from 'preact';
import style from './style.css';
import { useEffect } from 'preact/compat';

const NavigationBurger = (props) => {

  useEffect(() => {
    if (props.burgerState === 'close'){
      document.getElementById('checkbox').checked = false;
    }
  }, [props.burgerState]);

  const mainStyle = props.globalNavStyle === 'desktop' ? `desktop` : `burger`;
  const themeStyle = props.theme === 'dark' ? `dark` : `light`;

  return (
    <div className={`${style[mainStyle]} ${style[themeStyle]}`}>
     <input id={'checkbox'}
            type="checkbox"
            onChange={(e) => props.updateBurgerState(e.target.checked ? 'open' : 'close')}
     />
      <span />
      <span />
      <span />
    </div>
)}
export default NavigationBurger;

