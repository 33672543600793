/**
 * Extracts the Google Drive file ID out of a URI or other string.
 *
 * @param {string} uri The URI that contains a Google Drive file ID (for example: Folders, Google Docs or Images.
 * Does not include Drive IDs!). Can also be used on a plain file ID.
 * @returns {string} Returns the file ID or empty string if not found.
 */
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function extractFid(uri) {
  const re = /1[a-zA-Z0-9_-]{32,43}/;
  const fid = uri.match(re);
  if (!fid) {
    return '';
  }
  return fid[0];
}

export default extractFid;
