const config = {
  company: 'vib GmbH' || 'vib GmbH',
  logoURI:
    'https://utils.vib-development.ch/vib/logo.svg' || 'https://utils.vib-development.ch/vib/logo.svg',
  logoWhiteURI:
    'https://utils.vib-development.ch/vib/logo-white.svg' || 'https://utils.vib-development.ch/vib/logo-white.svg',

  address: 'vib GmbH,Grabenstrasse 12,6340 Baar'?.split(',') || [
    'vib GmbH',
    'Grabenstrasse 12',
    '6340 Baar',
  ],
  contact: '+41 (0)41 763 16 94,support@vib-gmbh.ch'?.split(',') || [
    '+41 (0)41 763 16 94',
    'support@vib-gmbh.ch',
  ],
  apibaseuri:
    'https://api.manual.vib-gmbh.ch' ||
    'https://api.main.vib-connect-manual.ch4.amazee.io',
  appname: 'vib ECM Manual' || 'vib connect Manual',
  appdomain: 'manual.vib-gmbh.ch' || 'manual.vib-gmbh.ch',
  appurl: 'https://manual.vib-gmbh.ch' || 'https://manual.vib-gmbh.ch',
  statuspage: 'https://status.vib-development.ch' || 'https://status.vib-development.ch',
  support: 'support@vib-gmbh.ch' || 'support@vib-gmbh.ch',
  root: '11y-gBjP2DfbuxIDMaCH0PXl06q252GME' || '',
  footer: '13NBqt6hX_eHwYQuwdwRZ-M-PNwhwcFTS' || '',
  disclaimer: '13RxLbNCcuVhG4KGn2KYcCnw6vf7JYWmkT62zuVaXgjs' || '',
  languages: 'DE,EN-GB,ES,FR,IT'?.split(',') || ['DE','EN-GB'],
  screenBreak: '930' || 930
};

export default config;
