import config from '../../config';
import style from './style.css';
import iconLT from './icon-lt.svg';
import iconDK from './icon-dk.svg';
import { h } from 'preact';
import {useCallback, useEffect} from "preact/compat";
import axios from "axios";
import {useState} from "preact/hooks";
import extractFid from "../../utils/extractFid"; // with import

const printButton = (props) => {
  const [homeFid, homeFidSet] = useState(null);

  const fetchHome = useCallback(async () => {
    const response = await axios.get(`${config.apibaseuri}/drive/home/${config.root}`,
      {withCredentials:true}
    );
    homeFidSet(response.data.id);
  }, []);

  useEffect(() => {
    fetchHome();
  }, []);

  const mainStyle = props.globalNavStyle === 'desktop' ? `desktop` : props.burgerState === 'open' ? `burgerOpen` : `burgerClose`;
  const themeStyle = props.theme === 'dark' ? `dark` : `light`;

  if (!props?.status?.authorized) {
    return <div className={`${style[mainStyle]} ${style.hidden}`} />
  }

  return (
    <div className={`${style[mainStyle]} ${style[themeStyle]}`}>
      <img src={props.theme === 'dark' ? iconDK : iconLT} alt={"print document"} />
      <button onClick={() => {
        const currentPathname = window.location.pathname.split('/');
        const apiURL = [currentPathname[currentPathname.length-2] || homeFid, currentPathname[currentPathname.length-1]];

        if (extractFid(apiURL[0])) {
          const href = `${config.apibaseuri}/docs/pdfprint/${config.disclaimer}/${apiURL[0]}/${apiURL[1]}`;
          props.updateBurgerState('close');
          return window.open(href);
        }
      }} />
    </div>
  )
}

export default printButton;
