import { h } from 'preact';

const Exception = (props) => {
  let message = null;

  switch (props.msg) {
    case 'No authorization token was found':
      message =
      ' Please log in to use this application.';
      break;
    case 'jwt expired':
      message =
        'Your session has expired. Please log in again to use this application.';
      break;
    default:
      message =
        'An unexpected error has occurred. If this behavior persists, please contact the service desk.';
  }

  return <p>{message}</p>;
};

export default Exception;
