import style from './style.css';
import config from '../../config';
import { h } from 'preact';
import iconLT from './icon-lt.svg';
import iconDK from './icon-dk.svg';

const LanguageSelection =(props) => {

  const mainStyle = props.globalNavStyle === 'desktop' ? `desktop` : props.burgerState === 'open' ? `burgerOpen` : `burgerClose`;
  const themeStyle = props.theme === 'dark' ? `dark` : `light`;

  /*if there is only one language the div block is a placeholder to have the login/logout button top right*/
  if (config.languages.length <= 1 || !props?.status?.authorized) {
    return <div className={`${style[mainStyle]} ${style.hidden}`} />
  }

  return (
    <div className={`${style[mainStyle]} ${style[themeStyle]}`}>
      <img src={props.theme === 'dark' ? iconDK : iconLT}  alt={'Select language'}/>
      <select
        onChange={(e) => {
          props.updateLanguage(e.target.value);
          props.updateBurgerState('close');}
        }>
        {
          config.languages.map((lan, index) => {
            if (lan === props.language) {
              return <option key={`lang-nav-${index}`} value={lan} selected>{lan.substring(0, 2)}</option>;
            }
            return <option key={`lang-nav-${index}`} value={lan}>{lan.substring(0, 2)}</option>;
          })
        }
      </select>
    </div>
  )
}
export default LanguageSelection;




