import { h } from 'preact';
import {route, Route, Router} from 'preact-router';

import NotFoundPage from '../routes/notfound';
import Header from './header';
import Auth from '../routes/auth';
import Page from '../routes/page';
import Docs from '../routes/docs';
import Footer from './footer';

import Stroke from './stroke';
import {useCallback, useState} from "preact/hooks";
import {useEffect} from "preact/compat";
import config from '../config';
import axios from "axios";

const App = () => {
  const getBrowserLanguage = () => {
    const browserLanguage = navigator.language.toUpperCase();
    if (
      config.languages.includes(browserLanguage.substring(0,2)) &&
      browserLanguage.substring(0,2) !== 'EN'
    ) {
      return browserLanguage.substring(0,2);
    }
    return config.languages[0];
  }

  const [language, setLanguage] = useState(getBrowserLanguage());
  const [deviceScreen, setDeviceScreen] = useState(window.innerWidth > 900 ? 'desktop' : 'mobile');
  const [theme, setTheme] = useState(window.matchMedia("(prefers-color-scheme: dark)").matches ? 'dark' : 'light');
  const [status, statusSet] = useState(null);

  const path = window.location.pathname.split('/');
  if (path[1] === '' || config.languages.includes(path[1])) {
    route(`/${language}`);
  } else if (path[1] === 'd') {
    route(`/d/${path[2]}/${language}`)
  } else if (!config.languages.includes(path[1])) {
    route(`/${language}`);
  }

  const getStatus = useCallback(async () => {
    const response = await axios.get(`${config.apibaseuri}/status`, {
      withCredentials: true,
    });
    statusSet(response.data);
  }, []);

  useEffect(() => {
    getStatus();
  }, [getStatus]);

  useEffect(() => {
    getStatus();
    const path = window.location.pathname.split('/');
    if (path[1] === '') {
      route(`/${language}`);
    }
    window.addEventListener('resize', function(){
      setDeviceScreen(window.innerWidth > config.screenBreak ? 'desktop':'mobile');
    }, true);
  }, []);

  return (
    <div id="preact_root">
      <Stroke screentheme={theme} />
      <Header language={language}
              updateLanguage={(newLanguage) => setLanguage(newLanguage)}
              menuKind={deviceScreen}
              screentheme={theme}
              updateTheme={(newTheme) => setTheme(newTheme)}
              status={status}
      />
      <Router>
        <Route path={'/:lid'} component={Docs} screentheme={theme} menuKind={deviceScreen} status={status} updateLanguage={(newLanguage) => setLanguage(newLanguage)} />
        <Route path={'/login'} component={Auth} />
        <Route path={'/logout'} component={Auth} />
        <Route path={'/p/:title'} component={Page} />
        <Route path={'/d/:tid/:lid'} component={Docs} screentheme={theme} menuKind={deviceScreen} status={status} updateLanguage={(newLanguage) => setLanguage(newLanguage)} />
        <NotFoundPage default />
      </Router>
      <Footer language={language} menuKind={deviceScreen} screentheme={theme} />
    </div>
  );
};

export default App;
