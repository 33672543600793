import { h } from 'preact';
import { useCallback, useEffect, useState } from 'preact/hooks';
import axios from 'axios';
import iconInLT from './icon-in-lt.svg'; // with import
import iconOutLT from './icon-ou-lt.svg'; // with import
import iconInDK from './icon-in-dk.svg'; // with import
import iconOutDK from './icon-ou-dk.svg'; // with import

import config from '../../config';
import style from './style.css';

const Login = (props) => {

  const imgElement = props.status && !props.status.authorized ? props.theme === 'dark' ? iconInDK : iconInLT : props.theme === 'dark' ? iconOutDK : iconOutLT;
  const mainStyle = props.globalNavStyle === 'desktop' ? 'desktop' : props.burgerState === 'open' ? 'burgerOpen' : 'burgerClose';
  const themeStyle = props.theme === 'dark' ? `dark` : `light`;

  return (
    <a className={`${style[mainStyle]} ${style[themeStyle]}`} href={`/${props.status && !props.status.authorized ? 'login' : 'logout'}`}>
      <img src={imgElement} alt={props.status && !props.status.authorized ? "login here" : "logout here"} />
    </a>
  );
};

export default Login;
