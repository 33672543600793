// TODO: #99 (https://github.com/vib-gmbh/vib-connect-manual/issues/#90)
import { h } from 'preact';
import style from './style.css';
import { useState } from 'preact/hooks';
import { useCallback, useEffect } from 'preact/compat';
import axios from 'axios';
import config from '../../config';

import Logo from '../logo';
import Login from '../login';
import LanguageSelection from '../languageSelection';
import NavigationList from '../navigationlist';
import NavigationBurger from '../navigationburger';
import ThemeButton from '../themebutton';
import PrintButton from '../printbutton';


const Header = (props) => {
  const [data, dataSet] = useState(null);

  const fetchData = useCallback(async () => {
    const response = await axios.get(`${config.apibaseuri}/drive/nav/${config.root}`, {
      withCredentials: true,
    });
    dataSet(response.data);
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const [burgerMenuState, setBurgerMenuState] = useState('close');
  const mainStyle = props.menuKind === 'desktop' ? `desktop` : burgerMenuState === 'open' ? `burgerOpen` : `burgerClose`;
  const themeStyle = props.screentheme === 'dark' ? `dark` : `light`;

  return (
    <header className={`${style[mainStyle]} ${style[themeStyle]}`} >
      <div >
        <Logo language={props.language}
              globalNavStyle={props.menuKind}
              updateBurgerState={(newBurgerState) => setBurgerMenuState(newBurgerState)}
              theme={props.screentheme}
        />
        <div />
          <NavigationList globalNavStyle={props.menuKind}
                          burgerState={burgerMenuState}
                          updateBurgerState={(newBurgerState) => setBurgerMenuState(newBurgerState)}
                          data={data}
                          language={props.language}
                          theme={props.screentheme}
        />
        <div />
        <div className={style.icon}>
          <ul>
            <li>
              <LanguageSelection language={props.language}
                                 globalNavStyle={props.menuKind}
                                 burgerState={burgerMenuState}
                                 updateBurgerState={(newBurgerState) => setBurgerMenuState(newBurgerState)}
                                 updateLanguage={props.updateLanguage}
                                 theme={props.screentheme}
                                 status={props.status}
            />
            </li>
            <li>
              <PrintButton globalNavStyle={props.menuKind}
                           burgerState={burgerMenuState}
                           updateBurgerState={(newBurgerState) => setBurgerMenuState(newBurgerState)}
                           theme={props.screentheme}
                           status={props.status}
            />

            </li>
            <li>
              <ThemeButton globalNavStyle={props.menuKind}
                           burgerState={burgerMenuState}
                           updateBurgerState={(newBurgerState) => setBurgerMenuState(newBurgerState)}
                           theme={props.screentheme}
                           updateScreenTheme={props.updateTheme}
            />
            </li>
            <li>
              <Login globalNavStyle={props.menuKind}
                     burgerState={burgerMenuState}
                     updateBurgerState={(newBurgerState) => setBurgerMenuState(newBurgerState)}
                     theme={props.screentheme}
                     status={props.status}
            />
            </li>
          </ul>
        </div>
    </div>
    <NavigationBurger globalNavStyle={props.menuKind}
                      burgerState={burgerMenuState}
                      updateBurgerState={(newBurgerState) => setBurgerMenuState(newBurgerState)}
                      theme={props.screentheme}
    />
    </header>
  );
};

export default Header;
