import { h } from 'preact';
import style from './style.css';
import IconLT from './icon-lt.svg'; // with import*/
import IconDK from './icon-dk.svg'; // with import*/

const themeButton = (props) => {

  const mainStyle = props.globalNavStyle === 'desktop' ? `desktop` : props.burgerState === 'open' ? `burgerOpen` : `burgerClose`;
  const themeStyle = props.theme === 'dark' ? `dark` : `light`;

  return (
    <a className={`${style[mainStyle]} ${style[themeStyle]}`}
    >
      <img src={props.theme === 'dark' ? IconDK : IconLT} alt={"change Theme"} />
      <input type={'checkbox'}
             id={`themeBut`}
             onChange={(e) => {
               props.updateScreenTheme(e.target.checked ? 'dark' : 'light');
               props.updateBurgerState('close');}
             }
             value={props.theme === 'dark' ? `selected` : `deselected`}
      />
    </a>
  )
}

export default themeButton;






