import { h} from "preact";
import style from './style.css';

const Stroke = (props) => (

  <div className={style.stroke}>
    <svg className={props.screentheme === 'dark' ? style.dark : style.light} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 10" preserveAspectRatio="xMidYMin meet">
      <rect
        className={style.bar}
        width="1000%"
        height="1000%"
        transform="rotate(45 100 50) translate(-5 0)"
      ></rect>
      <rect
        className={style.notbar}
        width="1000%"
        height="1000%"
        transform="rotate(45 100 50) translate(-3 0)"
      ></rect>
    </svg>
  </div>
);

export default Stroke;
