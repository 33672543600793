// TODO: #46 (https://github.com/vib-gmbh/vib-connect-flix/issues/46)
import { h } from "preact";
import style from './style.css';

import config from '../../config';

const Logo = (props) => {
  return (
      <a href={`/${props.language}`}
         className={props.globalNavStyle === 'desktop' ? style.desktop : style.mobile}
         onClick={(e) => props.updateBurgerState('close')}
      >
        <img src={props.theme === 'dark' ? config.logoWhiteURI : config.logoURI} alt={config.company && `Logo ${config.company}` || ''} />
      </a>);
}

export default Logo;
