// TODO: #43 (https://github.com/vib-gmbh/vib-connect-flix/issues/43)
import {h} from "preact";
import style from "./style.css";
import { Link } from 'preact-router/match';
import config from "../../config";
import {useState} from "preact/hooks";
import {useCallback, useEffect} from "preact/compat";
import axios from "axios";
import Exception from "../exception";


const Footer = (props) => {
  const [data, dataSet] = useState(null);

  const fetchData = useCallback(async () => {
    // const response = await axios.get(`http://localhost:3000/drive/list`);
    const response = await axios.get(`${config.apibaseuri}/drive/footer/${config.footer}`, {
      withCredentials: true,
    });
    dataSet(response.data);
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const mainTheme = props.screentheme === 'dark' ? `dark` : `light`;
  const footer = `footer`;
  const deviceScreen = props.menuKind === 'desktop' ? `desktop` : `mobile`;

  return (
    <footer className={`${style[footer]} ${style[mainTheme]} ${style[deviceScreen]}`} >
      <div class={style.container}>
        <div class={style.footer__text} >{`Release: 1.7.1-beta.0`}</div>
        <nav id="menu-footer" class={style.footer__nav}>
          <ul class={style.footer__nav}>
            {(data && data.error && <div />) || data && data.map((item) => {
                return (
                  <li>
                    <Link
                      className={style.footer__link}
                      href={`/d/${item.id}/${props.language}`}
                    >
                      {item.name}
                    </Link>
                  </li>
                );
            })}
            <li>
              <a
                className={style.footer__link}
                href={config.statuspage}
                target="_blank"
                rel="noreferrer"
              >
                Status
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </footer>
  );
}
export default Footer;
